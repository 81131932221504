<template>
  <pcis-steps
    :form-type="formType"
    ref="steps"
  >
    <el-card>
      <el-icon>
        <i class="bi bi-check2-circle"></i>
      </el-icon>
      <p v-text="this.completedNoText()" />
      <p v-for="content in tm('contents')"
        :key="content"
        v-text="content"
      />
    </el-card>
    <template #buttons>
      <div class="steps-button">
        <el-button type="primary" round @click="onBtnClick">
          {{ $t("common.button.complete") }}
        </el-button>
      </div>
    </template>
  </pcis-steps>
</template>

<style scoped>
.el-card {
  margin-top: 20px;
  text-align: center;
  font-size: 18px;
}
.el-icon {
  font-size: 8em;
  color: #67c23a;
}

.steps-button {
  margin: 20px 0px;
  text-align: center;
}
.el-button {
  width: 100%;
  max-width: 300px;
}
</style>

<script>
import BaseStep from "@/views/personal/steps/BaseStep.vue";
// import application from "../../../http/apis/personal/application";
import { i18nPluralizationBase } from "../../../libs/common";

export default {
  extends: BaseStep,
  name: "PersonalComplete",
  data() {
    return {
      appNo: this.$store.state.personal.form.id
    }
  },
  methods: {
    onBtnClick() {
      // this.$store.commit("resetPersonalState", false);
      this.$router.push({name: "PersonalForbidden", params: { locale: this.$i18n.locale }, query: {type: "C"}})
    },
    completedNoText() {
      return i18nPluralizationBase(this.$i18n.t('personal.complete.appNo'), { appNo: this.appNo });
    }
    // checkIfAllowAccess() {
    //   this.$store.commit("resetPersonalForm")
    //   let id = this.$store.state.personal.form.id;
    //   if(!id) {
    //     this.$refs.steps.goFirst();
    //     return;
    //   }

    //   await handlePayment(personal.form.id)

    //   if (!this.$store.state.personal.complete) {
    //     application.postSendSubmissionEmail(this.$store.getters.personalJWT, id).catch((err) => {
    //       console.log(err)
    //     })
    //   }
    //   this.$store.commit("updateComplete", true)
      
    // }
  },
  // created() {
  //   this.checkIfAllowAccess();
  // },
  // beforeRouteUpdate() {
  //   this.checkIfAllowAccess();
  // }
};
</script>
